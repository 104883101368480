@import 'components/mixins.scss';

:global(.ant-layout) {
  background: $body-background !important;
}

.pageContainer {
  margin: 0 auto 100px;
  max-width: 1300px;
  width: 80%;
  background-color: #fff;
  padding: 100px 0;
  border-radius: 20px;
  word-break: keep-all;
  @media (max-width: $sm-max-width) {
    width: 90%;
    padding: 30px 0;
  }
}

.introduceWrapper {
  position: relative;
  z-index: 3;
  background-color: #fff;
  word-break: keep-all;
  margin: 40px auto 0 auto;
  padding: 0 30px 0 30px;
  max-width: 800px;
}

.introduceWrapperWide {
  position: relative;
  z-index: 3;
  background-color: #fff;
  word-break: keep-all;
  margin: 40px auto 0 auto;
  padding: 0 40px 0 40px;
  max-width: 1080px;
  @media (max-width: $sm-max-width) {
    padding: 0 20px 0 20px;
  }
}

.introduceMainTitle {
  margin-top: 40px;
  position: relative;
  z-index: 3;
  font-size: 40px;
  color: $black;
  font-weight: 400;
  line-height: 1.22;
  text-align: center;
  // text-decoration: underline;
  // text-decoration-color: $blue-light;
  // text-decoration-thickness: 1px;
  // text-underline-offset: 1px;
  @media (max-width: $sm-max-width) {
    margin-top: 20px;
    font-size: rem(22);
    text-decoration-thickness: 3px;
    font-weight: 500;
  }
}
.introduceTitle {
  // font-size: rem(70);
  position: relative;
  z-index: 3;
  font-size: rem(30);
  color: $black;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -1.03px;
  margin-top: 5vh;
  // margin-left: auto;
  // margin-right: auto;
  // padding-left: 30px;
  // padding-right: 30px;
  // max-width: 800px;

  p {
    margin: 0;
    padding: 0;
  }

  // @media (max-width: $lg-max-width) {
  // }

  // @media (max-width: $md-max-width) {
  // }

  @media (max-width: $md-max-width) {
    font-size: rem(28);
  }

  @media (max-width: $sm-max-width) {
    font-size: rem(20);
    font-weight: 500;
  }
}

.draft {
  p {
    margin-bottom: rem(20);
    // margin: 0;
    // padding: 0;
  }
  p:empty::before {
    content: '\feff';
  }
}
